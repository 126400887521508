//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PrimaryButton from "@/components/shared/primary-button";
export default {
  components: {PrimaryButton},
  methods:{
    goToDashboard(){
      this.$router.push("/");
    }
  }

}
