
const initState = () => ({
  showFilter: false,
  categories: [],
  metals:[],
  category: {},
})

export const state = initState

export const getters = {
  getMetals: (state) => state.metals,
}

export const mutations = {
  setCategories(state, {categories}) {
    state.categories = categories
  },
  reset(state) {
    Object.assign(state, initState())
  },
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setCategory(state, {category}){
    state.category = category
  }
}

export const actions = {
  async getCategories({commit}, {filters}) {
    let categories = [];
    try{
      const result = await this.$axios.$get(`/webpos/catalogs?${new URLSearchParams(filters).toString()}`);
      if(result && !result.apiMessages.hasErrors) {
        categories = result.value;
      }
      else{
        this.$toast.error("Error while getting catalogs");
      }
    }
    catch(e){
      this.$toast.error("Error while getting catalogs");
    }

    commit('setCategories', {categories})
  },

  async fetchChildCategories({commit}, {filters}) {
    let childCategories = [];
    try{
      const result = await this.$axios.$get(
        `/webpos/catalogs?${new URLSearchParams(filters).toString()}`);
      if(result && !result.apiMessages.hasErrors) {
        childCategories = result.value;
      }
      else{
        this.$toast.error("Error while getting catalogs");
      }
    }
    catch(e){
      this.$toast.error("Error while getting catalogs");
    }

    return childCategories;
  },

  fetchMetals({commit}) {
    return this.$axios.$get('/Metals/ShownInCatalog').then((metals) => {
      commit('setMetals', {
        metals: metals.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
  async getCatalogById({commit}, catalogId) {
    return await this.$axios.get(`/ProductCategories/${catalogId}`).then(response =>{
      commit('setCategory', {category: response.data})
    })
  }
}
