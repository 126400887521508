export const actions = {
  async getCustomerCategories(){
    let customerCategories = [];
    try
    {
      const result = await this.$axios.$get(`webpos/customer-categories`);
      if(result && !result.hasErrors){
        customerCategories = result.value;
      }
      else{
        this.$toast.error('Error while getting customer categories');
      }
    }
    catch(e){
      this.$toast.error('Error while getting customer categories');
    }
    return customerCategories;
  }
}
