const initState = () => ({
  modules: []
})

export const state = initState;

export const mutations ={
}
export const actions ={
  async getApplicationModuleFieldsByModuleCode({}, {moduleCode}){
    let applicationModuleFields = [];

    try{
      let result = await this.$axios.get(`webpos/modules/${moduleCode}/fields`);
      if(result?.apiMessages && result.apiMessages.hasErrors && result.apiMessages.serverErrors?.length > 0){
        this.$toast.error(result.apiMessages.serverErrors[0]);
      }
      else if (result.data?.value){
        applicationModuleFields = result.data.value;
      }
    }
    catch(e){
     this.$toast.error("Error while getting application module fields");
    }
    return applicationModuleFields;
  },

  async updateApplicationModuleFields({}, {moduleFields}){
    try{
      await this.$axios.post(`webpos/fields`, moduleFields);
      this.$toast.success("Application module fields saved successfully");
      return true;
    }
    catch(e){
      this.$toast.error("Error while saving application module fields");
      return false;
    }
  },

}
