const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {

}

export const actions = {
  async uploadTmpFile({commit}, {file})
  {
    let tmpFileUrl= null
    try {
      const formData = new FormData()
      formData.append('file', file)
      const result = await this.$axios.$post('/upload/tmp-file', formData)
      if (result && result.tmpFileUrl) {
        tmpFileUrl = result.tmpFileUrl
      }
    }
    catch{
      this.$toast.error("Error while uploading file")
    }

    return tmpFileUrl;
  },

  async deleteTmpFile({commit}, {file}){
    try{
      await this.$axios.$post('/upload/delete-tmp-file', file)
    }
    catch{
      console.error("Error while deleting file")
    }
  },

  async downloadFileByFileInfoID({}, {fileInfoID}){
    try{

      const response = await this.$axios.get(`webpos/file/${fileInfoID}`, {responseType: 'blob'});
      if(response){
        const contentDispositionHeader = response.headers['content-disposition']
        const contentType = response.headers['content-type']

        if (contentDispositionHeader && contentType) {
          const fullNameMatch = contentDispositionHeader.match(/filename\*=(.+)$/);
          let fullName = fullNameMatch ? fullNameMatch[1] : null;
          if (fullName) {
            fullName = decodeURIComponent(fullName.replace("UTF-8''", ''));
          }
          const file = new File(
            [response.data],
            fullName,
            {type: contentType}
          );

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(file);
          downloadLink.download = fullName;

          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      }
      else{
        this.$toast.error("Error while downloading file")
      }
    }
    catch(ex){
      this.$toast.error("Error while downloading file")
    }

  },



}
