import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  orderCreatingInProgress : false
})

export const state = initState

export const getters = {
}

export const mutations = {
  setOrderCreatingInProgress(state, inProgress){
    state.orderCreatingInProgress = inProgress
  }
}

export const actions = {
  async createCustomJobOrder({commit}, customJobOrder){
    let insertedCustomJobOrder = null
    try{
      commit('setOrderCreatingInProgress', true)
      let result = await this.$axios.$post(`/webpos/custom-job-order/create`, customJobOrder);
      if(result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0){
        this.$toast.error(result.apiMessages.serverErrors[0]);
      }
      else if (result.value){
        insertedCustomJobOrder = result.value;
      }

    }
    catch(error){
      if(error.response && error.response.data && error.response.data.Message){
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error("Error while creating custom job order");
    }
    finally {
      commit('setOrderCreatingInProgress', false)
    }
    return insertedCustomJobOrder;
  },

  async createCustomPriceQuote({commit}, customPriceQuote){
    let insertedCustomPriceQuote = null
    try{
      commit('setOrderCreatingInProgress', true)
      let result = await this.$axios.$post(`/webpos/custom-price-quote/create`, customPriceQuote);
      if(result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0){
        this.$toast.error(result.apiMessages.serverErrors[0]);
      }
      else if (result.value){
        this.$toast.success("Custom price quote created successfully");
        insertedCustomPriceQuote = result.value;
      }

    }
    catch(error){
      if(error.response && error.response.data && error.response.data.Message){
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error("Error while creating custom price quote");
    }
    finally {
      commit('setOrderCreatingInProgress', false)
    }
    return insertedCustomPriceQuote;
  },

  async fetchCustomOrders({commit, state}, {pagingInfo, filters, showClosedOrders}){
    const url = `/webpos/custom-order/`;
    let customOrders = [];

    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    filter.showClosedOrders = showClosedOrders;
    const result = await this.$axios.$post(url, filter);

    if(result && !result.hasErrors){
      customOrders = result;
    }
    else{
      this.$toast.error('Error while getting custom orders')
    }
    return customOrders;
  },

  async updateCustomOrderInstructions({}, {orderId, customItemId, instructions}){
    try{
      await this.$axios.put(`/webpos/custom-order/instructions`, {
        orderId: orderId,
        customItemId: customItemId,
        instructions: instructions
      });

      this.$toast.success("Custom order instructions updated successfully");
    }
    catch(error){
      this.$toast.error("Error while updating custom order instructions");
    }
  },
  async updateCustomPriceQuoteStatus({}, {priceQuoteID, newStatusCode, payment}){
    try{
      const result = await this.$axios.put(`/webpos/custom-price-quote/${priceQuoteID}/status`, {
        newStatusCode: newStatusCode,
        payment: payment
      })

      if (result?.data.apiMessages.hasErrors == false){
        return result.data.value
      }
    }
    catch(error){
      this.$toast.error("Error while updating custom price quote status");
      return null;
    }

    this.$toast.error("Error while updating custom price quote status");
    return null;
  },

  async getCustomOrderByID({}, {orderID, storeID}){
    let itemType = null;
    try{
      const result = await this.$axios.$get(
        `/webpos/custom-order/${orderID}?storeID=${storeID}`);

      if(result && !result.hasErrors){
        itemType = result.value;
      }

      else{
        this.$toast.error('Error while getting selected custom order');
      }
    }
    catch(e){
        if(e.response.status === 404){
          this.$toast.error('The selected custom order does not exist in the current store');
          return itemType
        }
        this.$toast.error('Error while getting selected custom order');
    }
      return itemType;
  },

  async getInitialCustomOrderData({}){
    let initialData = null;
    try{
      const result = await this.$axios.$get(
        `/webpos/custom-order/initial-data`);

      if(result && !result.hasErrors){
        initialData = result.value;
      }

      else{
        this.$toast.error('Error while initializing custom order data');
      }
    }
    catch(e){
      this.$toast.error('Error while initializing custom order data');
    }
    return initialData;
  },

  async updateCustomOrder({commit}, {id, customOrder}){
    commit('setOrderCreatingInProgress', true)
    let success = true;
    try{
      await this.$axios.$post(`/webpos/custom-order/${id}`, customOrder);
      this.$toast.success('Custom order updated successfully');
    }
    catch(e){
      this.$toast.error('Error while updating custom order data');
      success = false;
    }
    finally {
      commit('setOrderCreatingInProgress', false)
    }
    return success;
  }
}
