import {formActions} from '@/enums/formActions'

const initState = () => ({
  itemGroups: [],
})

export const state = initState

export const getters = {
  getItemGroups: (state) => state.itemGroups,
}

export const mutations = {
  setItemGroups(state, {itemGroups}) {
    state.itemGroups = itemGroups
  },
}

export const actions = {
  async fetchItemGroups({commit}) {
    let itemGroups = [];
    try{
      let result = await this.$axios.$get('webpos/itemgroup/list-items')
      if(result && !result.hasErrors){
        itemGroups = result.value;
      }
      else{
        this.$toast.error('Error while getting item groups');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item groups');
    }

    return itemGroups;

  },

  async fetchItemTypesByAdminSettingCode({commit}, {filters}) {
    let paginatedResult = [];
      try {
        let result =  await this.$axios.get(`/webpos/itemgroup/get-item-types/`, {params: filters});
        paginatedResult.list = result.data;
        paginatedResult.itemCount = result.headers['x-total-count'];
      } catch(error){
        console.error('Error fetching item types by item groups,', error);
        this.$toast.error('Error fetching item types by item groups');
      }
      return paginatedResult;
  },
  async fetchAcceptedItemGroupsForCustomOrders() {
    let acceptedItemGroups = [];
    try {
      const result = await this.$axios.$get(`webpos/itemgroup/get-accepted-item-groups-for-co`);
      if(result && !result.hasErrors){
        acceptedItemGroups =  result.value;
      }
      else{
        this.$toast.error('Error while getting accepted item groups for custom orders');
      }
    }
    catch(error){
      this.$toast.error('Error while getting accepted item groups for custom orders');
    }
    return acceptedItemGroups;
  },
  async getItemTypeListItemsByItemGroupID({commit}, {itemGroupID, params}){
    let paginatedItemTypes = [];
    try{
      const result = await  this.$axios.get(`/webpos/itemgroup/${itemGroupID}/itemtypes/list-items`, {params: {pageNo: params.pageNo,pageSize: params.pageSize,query: params.query}});
      if(result && !result.data.hasErrors && result.data.value){
        paginatedItemTypes.itemTypes = result.data.value;
        paginatedItemTypes.itemCount = result.headers['x-total-count'];
      }
      else{
        this.$toast.error('Error while getting item types by item group id');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item types by item group id');
    }
    return paginatedItemTypes;
  },
  async getCustomAttributesByItemGroupID({commit}, {itemGroupID, filters}){
    let customAttributes = [];
    try{
      const result = await this.$axios.$get(`/webpos/itemgroup/${itemGroupID}/custom-attributes?${new URLSearchParams(filters).toString()}`);
      if(result && !result.hasErrors){
        customAttributes = result.value;
      }
      else{
        this.$toast.error('Error while getting item types by item group id');
      }
    }
    catch(e){
      this.$toast.error('Error while getting custom attributes by item group id');
    }
    return customAttributes;
  },

  async getItemGroups({commit}, filter) {
    let itemGroups = [];
    try{
      const result = await this.$axios.$post('webpos/itemgroup/', filter);

      if(result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0){
        this.$toast.error('Error while getting item groups');
      }
      else {
         itemGroups = result.value;
      }
    }
    catch {
      this.$toast.error('Error while getting item groups');
    }

    return itemGroups;

  },
}
