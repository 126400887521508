const initState = () => ({
  configurationState: {
    apiUrl: '',
    applicationId: '',
    applicationSecret: '',
    accessToken: '',
    refreshToken: '',
    expiredAt: '',
  },
})

export const state = initState

export const mutations = {
  setConfiguration(state, {configuration}) {
    state.configurationState = configuration.value
  },
}

export const actions = {
  async fetchConfiguration({commit}) {
    try {
      const configurationResponse = await this.$axios.$get('/webpos/payments/get-square-configuration')
      if (configurationResponse && configurationResponse.value) {
        commit('setConfiguration', {configuration :configurationResponse})

        return JSON.parse(JSON.stringify(configurationResponse.value)) // itt referenciat ad is megoli a v-modelt, nem ertem miert csak itt van ez.
      }
    }catch (e) {
      if (e.response.status == 404){
        return {}
      }else {
        this.$toast.error(e)
        return {}
      }
    }

  },
  async obtainToken({commit}, tokenRequest) {
    try {
      return await this.$axios.$post('/webpos/payments/obtain-square-token', tokenRequest)
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response
      }
    }
  },
  async connectTerminal({commit}, connectVM) {
    try {
      return await this.$axios.$post('/webpos/payments/connect-square-terminal', connectVM)
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response.data
      }
    }
  },
  async addPayment({commit}, paymentData) {
    try {
      return await this.$axios.$post('/webpos/payments/add-payment', paymentData)
    }
    catch (ex) {
      if (ex.response && ex.response.data) {
        return ex.response.data
      }
    }
  },
  async insertSquareCard({commit},{card, customerID} ){
    return await this.$axios
      .$post(`webpos/credit-card/insert-square-card/${customerID}`, card)
  },
  async tokenize({commit},{paymentMethod}) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  },

  async saveConfiguration({commit},{configurationVm}){
    try {
       const configResult = await this.$axios.$post('/webpos/payments/save-square-configuration',configurationVm);
      if (configResult && configResult.apiMessages.hasErrors)
      {
        this.$toast.error(configResult.apiMessages.serverErrors[0]);
      }
      else
      {
        this.$toast.success("Configuration saved successfully!");
        return configResult.value;
      }
    }catch (error) {
      if (error.response) {
        this.$toast.error(error.response.data.Message);
      }  else {
        this.$toast.error(error);
      }
      return null
    }

  }


}
